import React, { useState, useEffect, useCallback, useRef  } from 'react';
import { useParams } from 'react-router-dom';
import importMedia from '../importMedia';

// Function to dynamically import JSON files
const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => {
        images[item.replace('./', '')] = r(item);
    });
    return images;
};

// Import all project JSON files
//said order:  ['CEMC', 'WO','SCORE', 'NOSI', 'SC', 'SDD', 'FR', 'TA' ,'OG',   'VL',  'COPA', 'LMIH'] (31.01.2025)
// OG list 
// const whitelist = ['001_CEMC', '002_WO','003_SCORE', '004_NOSI', '005_SC', '006_SDD', '007_FR', '008_TA' ,'OG',   'VL',  'COPA', 'LMIH'];
const whitelist = ['001_CEMC', '002_WO','003_SCORE', '004_NOSI', '006_SDD', '007_FR' ,'OG',   'VL',  'COPA', 'LMIH'];

const projectData = Object.keys(importAll(require.context('../data/projects', false, /\.json$/)))
  .filter(key => whitelist.includes(key.replace('.json', '')))
  .reduce((obj, key) => ({ ...obj, [key]: importAll(require.context('../data/projects', false, /\.json$/))[key] }), {});



function Carousel() {
    const { lang = 'en' } = useParams();
    const [projects, setProjects] = useState([]);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [delay, setDelay] = useState(false);
    var timer = useRef(null);
    const [isHovered, setIsHovered] = useState(false); // State to track hover

    const updateCarousel = useCallback(
        (index) => {
            if (!projects.length) return;
            if (document.getElementById('carousel-nav-arrow') == null) return; 

            // Update the arrow rotation and active dot
            const degree = (360 / projects.length) * index;
            const arrow = document.getElementById('carousel-nav-arrow');
            arrow.style.transform = `rotate(calc(-90deg + ${degree}deg))`;

            // Update the slide classes
            projects.forEach((_, i) => {
                const slide = document.getElementById(`carousel-slide-${i}`);
                slide.classList.add('carousel-slide-hidden');

                const image = slide.querySelector('.carousel-slide-image img');
                const header = slide.querySelector('.carousel-slide-header h1');
                const location = slide.querySelector('.carousel-slide-location div');

                image.classList.remove('carousel-slide-element-above', 'carousel-slide-element-under', 'carousel-slide-element-center');
                header.classList.remove('carousel-slide-element-above', 'carousel-slide-element-under', 'carousel-slide-element-center');
                location.classList.remove('carousel-slide-element-above', 'carousel-slide-element-under', 'carousel-slide-element-center');

                if (i === index) {
                    slide.classList.remove('carousel-slide-hidden');
                    image.classList.add('carousel-slide-element-center');
                    header.classList.add('carousel-slide-element-center');
                    location.classList.add('carousel-slide-element-center');
                } else if (i === (index - 1 + projects.length) % projects.length) {
                    image.classList.add('carousel-slide-element-above');
                    header.classList.add('carousel-slide-element-under');
                    location.classList.add('carousel-slide-element-under');
                } else if (i === (index + 1) % projects.length) {
                    image.classList.add('carousel-slide-element-under');
                    header.classList.add('carousel-slide-element-above');
                    location.classList.add('carousel-slide-element-above');
                }
            }
        );

        // Update the active dot
        document.querySelectorAll('.dot').forEach((dot, i) => {
            dot.classList.toggle('dot-active', i === index);
        });

        setCurrentSlideIndex(index);

        setDelay(true);
        setTimeout(() => setDelay(false), 2000);
        }, [projects]
    );

    useEffect(() => {
        // Convert projectData object to an array of projects
        const projectArray = Object.keys(projectData).map(key => ({
            id: key.replace('.json', ''),
            ...projectData[key][lang] || projectData[key]['en'] // Fallback to English if `lang` is not available
        }));
        setProjects(projectArray);
    }, [lang]);

    useEffect(() => {
        // Set up the automatic slide transition
        clearInterval(timer.current);
        const newTimer = setInterval(() => {
            updateCarousel((currentSlideIndex + 1) % projects.length);
        }, 5000);
        timer.current = newTimer;

        // Clean up the interval on unmount
        return () => clearInterval(newTimer);
    }, [currentSlideIndex, projects, updateCarousel]);

    

    const handleScroll = (e) => {
        if (!isHovered) return; // Do nothing if hovering over carousel
        
        e.preventDefault();
        e.stopPropagation();

        if (delay) return;

        clearInterval(timer.current);
        const newTimer = setInterval(() => {
            updateCarousel((currentSlideIndex + 1) % projects.length);
        }, 5000);
        timer.current = newTimer;

        let newIndex = currentSlideIndex;

        if (e.type === "wheel") {
            if (e.deltaY > 0) {
                newIndex = (currentSlideIndex + 1) % projects.length;
            } else if (e.deltaY < 0) {
                newIndex = (currentSlideIndex - 1 + projects.length) % projects.length;
            }
        } else if (e.type === "touchmove") {
            const currentTouchY = e.touches[0].pageY;
            if (e.lastTouchY > currentTouchY) {
                newIndex = (currentSlideIndex + 1) % projects.length;
            } else if (e.lastTouchY < currentTouchY) {
                newIndex = (currentSlideIndex - 1 + projects.length) % projects.length;
            }
            e.lastTouchY = currentTouchY;
        }

        updateCarousel(newIndex);
    };

    useEffect(() => {
        const handleTouchMove = (e) => handleScroll(e);
        const handleWheel = (e) => handleScroll(e);

        // Add event listeners with passive: false
        window.addEventListener('wheel', handleWheel, { passive: false });
        window.addEventListener('touchmove', handleTouchMove, { passive: false });

        return () => {
            window.removeEventListener('wheel', handleWheel, { passive: false });
            window.removeEventListener('touchmove', handleTouchMove, { passive: false });
        };
    });

    // Function to generate srcSet for different image sizes
    const getSrcSet = (projectId) => {
        var carouselImage = importMedia(`/media/projects/${projectId}/Processed/Carousel`);
        var carouselImageSrcSet = Object.entries(carouselImage?.[0]?.variants || {})
        .map(([width, path]) => `${path} ${width}w`)
        .join(', ');

        return carouselImageSrcSet;
    };

    const getSrc = (projectId) => {
        var carouselImage = importMedia(`/media/projects/${projectId}/Processed/Carousel`);
        return carouselImage?.[0]?.variants['400'];
    };

    const getDotStyles = (index, total) => {
        const angle = (360 / total) * index;
        return {
            transform: `rotate(${angle}deg) translate(200%) rotate(-${angle}deg)`
        };
    };

    return (
        <div
            id="carousel-content"
            
        >
            <div id="carousel">
                <div id="carousel-slides">
                    {projects.map((project, index) => (
                        <a
                            key={project.id}
                            href={`/${lang}/projects/${project.id}`}
                            id={`carousel-slide-${index}`}
                            className={`carousel-slide ${index === currentSlideIndex ? '' : 'carousel-slide-hidden'}`}
                            onMouseEnter={() => setIsHovered(true)}  // Set hover state to true
                            onMouseLeave={() => setIsHovered(false)} // Set hover state to false
                            onTouchStart={() => setIsHovered(true)}
                            onTouchEnd={() => setIsHovered(false)}
                        >
                            <div className="carousel-slide-image">
                                <img
                                    src={getSrc(project.id)}
                                    srcSet={getSrcSet(project.id)}
                                    alt={project.title}
                                    sizes="(min-width: 780px) calc(80vw - 128px), calc(90vw - 29px)"
                                    className={`carousel-slide-element-${index === currentSlideIndex ? 'center' : index === (currentSlideIndex - 1 + projects.length) % projects.length ? 'above' : 'under'}`}
                                />
                            </div>
                            <div className="carousel-slide-header">
                                <h1 className={`carousel-slide-element-${index === currentSlideIndex ? 'center' : index === (currentSlideIndex - 1 + projects.length) % projects.length ? 'under' : 'above'}`}>{project.title}</h1>
                            </div>
                            <div className="carousel-slide-location">
                                <div className={`carousel-slide-element-${index === currentSlideIndex ? 'center' : index === (currentSlideIndex - 1 + projects.length) % projects.length ? 'under' : 'above'}`}>
                                    <h2>{project.format.join(', ')}</h2>
                                    <p>{project.phrase}</p>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
                <div id="carousel-nav">
                    <div id="carousel-dots">
                        {projects.map((_, index) => (
                            <span
                                key={index}
                                className={`dot ${index === currentSlideIndex ? 'dot-active' : ''}`}
                                style={getDotStyles(index, projects.length)}
                            ></span>
                        ))}
                    </div>
                    <img
                        src="/media/VF_Logo_Vector.svg"
                        alt=""
                        id="carousel-nav-arrow"
                        className=""
                    />
                </div>
            </div>
        </div>
    );
}

export default Carousel;
