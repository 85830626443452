import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import importMedia from '../importMedia';
import importVideos from '../importVideos';
import ResponsiveImage from '../components/ResponsiveImage';
import AppIcon from '../components/AppStoreIcons';
import Markdown from 'react-markdown';
import DataProjectPage from '../data/pages/Projects.json';

// Function to dynamically import JSON files
const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => {
        images[item.replace('./', '')] = r(item);
    });
    return images;
};

const projectData = importAll(require.context('../data/projects', false, /\.json$/));


function Project() {
    const { lang = 'en', project } = useParams(); // Get language and project parameters from the URL
    const proData = DataProjectPage[lang] || DataProjectPage['en']; // Fallback to English if `lang` is not available
    const next_text = proData["next_project"];
    const prev_text = proData["prev_project"];
    const [projectDatas, setProjectData] = useState(null);
    const [error, setError] = useState(null);
    //const [projects, setProjects] = useState([]);
    const [nextProject, setNextProject] = useState(null);
    const [prevProject, setPrevProject] = useState(null);
    const [index,setIndex] = useState(0);

    useEffect(() => {
        // Dynamically import the JSON file based on the project parameter
        
        import(`../data/projects/${project}.json`)
            .then((data) => {
                // Set the project data based on the selected language
                setProjectData(data[lang] || data['en']);
            })
            .catch((err) => {
                console.error('Error loading project data:', err);
                setError('Project not found');
            });
    }, [lang, project]);

    useEffect(() => {
        const projectArray = Object.keys(projectData).map(key => ({
            id: key.replace('.json', ''),
        }));
    
        // Find the index of the current project
        let ind;
        for (let i = 0; i < projectArray.length; i++) {
            if (projectArray[i].id === project) {
                ind = i;
                break;  // exit the loop once the project is found
            }
        }
    
        // Ensure that the index is valid before proceeding
        if (ind !== undefined) {
            setIndex(ind); // update the index state
        }
    
    }, [project]); // Re-run the effect when `project` changes (or whenever it's relevant)

    useEffect(() => {
        if (index === null || projectData.length === 0) return;
    
        const projectArray = Object.keys(projectData).map(key => ({
            id: key.replace('.json', ''),
        }));
    
        const nextIndex = (index + 1) % projectArray.length;
        const prevIndex = (index - 1 + projectArray.length) % projectArray.length;
    
        setNextProject(projectArray[nextIndex].id);
        setPrevProject(projectArray[prevIndex].id);
    
        //console.log("Next Project: ", projectArray[nextIndex].id);
        //console.log("Prev Project: ", projectArray[prevIndex].id);
    
    }, [index]); // Re-run this effect whenever the `index` state changes

    const scrollTop = ()=> {
        window.scrollTo(0,0);
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!projectDatas) {
        return <div>Loading...</div>; // Show a loading state while fetching the data
    }

    const { title, client, format, services, phrase, synopsis, details, iframes=[] , iphoneLink, playLink} = projectDatas;

    const mainImage = importMedia(`/media/projects/${project}/Processed/Main`);
    const libImages = importMedia(`/media/projects/${project}/Processed/Library`);
    const videos = importVideos(`/media/projects/${project}/Processed/Videos`);
    const appIconPlay = importMedia(`/media/projects/${project}/Processed/IconGooglePlayStore`);
    const appIconApple = importMedia(`/media/projects/${project}/Processed/IconAppStore`);

    return (
        <div lang={lang} id="project" data-menu="project">
            
            <div id="project-header">
                <div>
                    <h1>{title}</h1>
                    <div id="project-subtitle">
                        <ul className='unstyledList'>
                            <li><b>Client:</b> {client}</li>
                            <li><b>Format:</b> {format.join(', ')}</li>
                            <li><b>Services:</b> {services.join(', ')}</li>
                        </ul>
                        <p>
                            <i>{phrase}</i>
                        </p>
                    </div>
                </div>
            </div>
            <div id="project-sections" className='zigzag2-desktop'>
                <section>
                    <div className="project-synopsis">
                        <div>
                            <h1>_Synopsis</h1>
                            <div>
                                
                                {synopsis.map((paragraph, index) => (
                                    <Markdown key={index}>{paragraph}</Markdown>
                                ))}
                            </div>
                        </div>
                        <ul className='unstyledList project-detail'>
                            {Object.entries(details).map(([key, value]) => (
                                <li key={key}><b>{key}:</b> {Array.isArray(value) ? value.join(', ') : value}</li>
                            ))}
                        </ul>
                    </div>
                    
                    <div>
                        {/* Import Main Image here */}
                        {mainImage && (
                            <ResponsiveImage image={mainImage[0]} sizes='(min-width: 780px) calc(60vw - 160px), calc(100vw - 96px)' />
                        )}
                    </div>
                </section>
                

                {/* Generate sections with library images */}
                {libImages.map((image, index) => {
                    if (index % 2 === 0) {
                        // Start a new section for every two images
                        return (
                            <section key={index} className='content-spacing'>
                                <div>
                                    <ResponsiveImage image={image} />
                                </div>
                                {/* Check if there is a next image to display */}
                                {libImages[index + 1] && (
                                    <div>
                                        <ResponsiveImage image={libImages[index + 1]} sizes='(min-width: 780px) calc(60vw - 160px), calc(100vw - 96px)' />
                                    </div>
                                )}
                            </section>
                        );
                    }
                    return null; // Skip if it's the second image of the pair
                })}
            </div>
            <div id="project-video-sections" >
                {videos.map((video, index) => {
                    return (
                        <section key={index}>
                            <video controls src={video.path} type="video/mp4" className='rounded-vid' />
                        </section>
                    );
                })}
                {iframes.map((url, index) => {
                    return (
                        <section key={index} className='frameLink'>
                            <iframe title={title} src={url} className='rounded-vid' allowFullScreen={true}/>
                        </section>
                    );
                })}

            </div>
            
            <div className = "store-icons">
                <AppIcon image={appIconApple[0]} url = {iphoneLink}/>
                <AppIcon image={appIconPlay[0]} url = {playLink}/>
                
            </div>
            

            <div id="next-prev-project">
                <NavLink onClick={scrollTop} to={`/${lang}/projects/${prevProject}`} className={"navLinkPrev"}>
                    &lt; {prev_text}
                </NavLink>
                <NavLink onClick={scrollTop} to={`/${lang}/projects/${nextProject}`} className={"navLinkNext"}>
                    {next_text} &gt;
                </NavLink>
                
            </div>
        </div>
    );
}

export default Project;
