import React from 'react';


function ResponsiveImage({ image, sizes = "(max-width: 400px) 400px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, (max-width: 1600px) 1600px" }) {
    // Generate the srcSet attribute
    //console.log(image);
    if (image === undefined) {
        // eslint-disable-next-line jsx-a11y/img-redundant-alt
        return <img
            alt='Image Not Found'
            className='rounded img100cover'
        />
    }
    const srcSet = Object.entries(image?.variants)
        .map(([width, path]) => `${path} ${width}w`)
        .join(', ');

    // Define the sizes attribute
    //const sizes = "";

    return (
        <img
            src={image?.variants['400']} // Default to 400w version, adjust as needed
            srcSet={srcSet}
            sizes={sizes}
            alt={image.name}
            className='rounded img100cover'
        />
    );
}

export default ResponsiveImage;
