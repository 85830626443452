import React, { useState } from 'react';
import { NavLink, useParams, useLocation, useNavigate } from 'react-router-dom';
import HeaderData from '../data/components/Header.json';

function Header() {
    const { lang = 'en' } = useParams(); // Default to 'en' if no lang parameter is present
    const text = HeaderData[lang] || HeaderData['en']; // Fallback to English if `lang` is not available
    const location = useLocation(); // Get the current location
    const navigate = useNavigate(); // Hook to programmatically navigate

    // State to manage menu visibility
    const [menuShown, setMenuShown] = useState(false);

    // Toggle menu visibility
    const toggleMenu = () => {
        setMenuShown(prevMenuShown => !prevMenuShown);
    };

    // Close the menu
    const closeMenu = () => {
        setMenuShown(false);
    };

    // Handle language change
    const handleLanguageChange = (event) => {
        const selectedLang = event.target.value;
        // Change the URL based on the selected language
        const currentPath = location.pathname.split('/').slice(2).join('/'); // Get the path after the language
        navigate(`/${selectedLang}/${currentPath}`);
    };

    return (
        <header>
            <div id='menu'>
                <div id="menu-lang-and-leading">
                    
                    <div id='leading'>
                        <NavLink to={`/${lang}/`} onClick={closeMenu}>
                            <img id="menu-logo" src="/media/home-logoW.svg" alt="Logo" />
                        </NavLink>
                    </div>
                    <div id="menu-lang-tablet">
                            <select name="lang" id="lang-select" value={lang} onChange={handleLanguageChange}>
                                <option value="en">EN</option>
                                <option value="lu">LU</option>
                                <option value="fr">FR</option>
                        </select>
                    </div>
                </div>
                
                <div id='trailing'>
                    <nav id="menu-items" className={menuShown ? 'active' : 'idle'}>
                        <ul>
                            <li>
                                <NavLink to={`/${lang}/`} end className={({ isActive }) => (isActive ? 'menu-selected' : '')} onClick={closeMenu}>
                                    {text.home}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/${lang}/projects`} className={({ isActive }) => isActive && location.pathname === `/${lang}/projects` ? 'menu-selected' : ''} onClick={closeMenu}>
                                    {text.projects}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/${lang}/distribution`} className={({ isActive }) => (isActive ? 'menu-selected' : '')} onClick={closeMenu}>
                                    {text.distribution}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/${lang}/about`} className={({ isActive }) => (isActive ? 'menu-selected' : '')} onClick={closeMenu}>
                                    {text.about}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/${lang}/team`} className={({ isActive }) => (isActive ? 'menu-selected' : '')} onClick={closeMenu}>
                                    {text.team}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/${lang}/contact`} className={({ isActive }) => (isActive ? 'menu-selected' : '')} onClick={closeMenu}>
                                    {text.contact}
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div id="menu-lang">
                    <select name="lang" id="lang-select" value={lang} onChange={handleLanguageChange}>
                        <option value="en">EN</option>
                        <option value="lu">LU</option>
                        <option value="fr">FR</option>
                        
                    </select>
                </div>
                <div id="menu-button">
                    <input
                        type="checkbox"
                        id="menu-checkbox"
                        checked={menuShown}
                        onChange={toggleMenu}
                    />
                    <div><span></span></div>
                </div>
            </div>

            <div id='menu-shade' className={menuShown ? 'active' : 'idle'}>

            </div>
        </header>
    );
}

export default Header;
