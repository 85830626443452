import React from 'react';


function AppIcon({ image, url }) {

    // Generate the srcSet attribute
    
    //console.log(srcSet);
    if (image === undefined) {
        // eslint-disable-next-line jsx-a11y/img-redundant-alt
        
        return <div></div>
    }
    console.log("Image" + image);
    console.log("URL" + url);
    const srcSet = Object.entries(image?.variants)
        .map(([width, path]) => `${path} ${width}w`)
        .join(', ');

    // Define the sizes attribute
    //const sizes = "";
    
    return (
        <a href = {url}>
            <img
            srcSet={srcSet}
            alt={image.name}
            />
        </a>
        
    );
}

export default AppIcon;